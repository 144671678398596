<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="progressNotes"
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="progressNotesPerPage"
      :server-items-length="totalProgressNotes"
      hide-default-footer
      class="elevation-1 ml-4"
    >
      <template v-slot:top>
        <v-card flat color="white">
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="delinquentFirstName"
                    dense
                    outlined
                    color="grey"
                    hint="Filter by Name"
                    persistent-hint
                    class="mt-8 mx-4"
                    solo
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                    ref="menu"
                    v-model="fromDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fromDate"
                        label="From Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="From Date "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="fromDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="fromDateSave"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    ref="menu"
                    v-model="toDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="toDate"
                        label="To Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="To Date "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="toDate"
                      :min="fromDate || ''"
                      @change="toDateSave"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="1">
              <v-btn
                class="mt-8"
                elevation="1"
                fab
                small
                @click="readAllProgressNotes()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn rounded disabled class="mt-10">{{
              currentCenter.name
            }}</v-btn>
          </v-row>
          <v-row>
            <v-col cols="12" sm="10"> </v-col>
            <v-dialog v-model="dialog" max-width="1200px" persistent>
              <template v-slot:activator="{ on }">
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  dark
                  class="mb-2"
                  v-on="on"
                  small
                  right
                  v-if="showCreate"
                  >Add Progress Note</v-btn
                >
              </template>
              <v-card>
                <v-form
                  ref="form"
                  v-model="valid"
                  style="padding: 20px"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="11">
                      <v-card-title class="mb-12">
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>
                    </v-col>
                    <v-col cols="1">
                      <v-spacer />
                      <v-btn
                        class="ml-0"
                        dark
                        icon
                        color="pink"
                        large
                        @click="close"
                        ><v-icon>mdi-close-circle</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="4" sm="4">
                          <v-select
                            v-model="progressNote.transferId"
                            :items="delinquents"
                            :item-text="
                              (item) =>
                                `${item.names} - ${item.delinquent.dateOfBirth} - ${item.delinquent.placeOfBirth.name}`
                            "
                            item-value="id"
                            label="select delinquent"
                            dense
                            outlined
                            :rules="[(v) => !!v || 'required']"
                            :disabled="viewProgressMode"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" xl="3" md="4">
                          <v-menu
                            ref="menu"
                            v-model="sessionDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="viewProgressMode"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="sessionDate"
                                label="Session Date"
                                append-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                :rules="[(v) => !!v || 'required']"
                                :disabled="viewProgressMode"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="sessionDate"
                              @change="sessionDateSave"
                              :disabled="viewProgressMode"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6" xl="3" md="4">
                          <v-text-field
                            v-model="progressNote.primaryDx"
                            label="primary D x"
                            hint="primary D x"
                            outlined
                            dense
                            :disabled="viewProgressMode"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" xl="3" md="4">
                          <v-text-field
                            v-model="progressNote.facility"
                            label="Facility name"
                            outlined
                            dense
                            :disabled="viewProgressMode"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-select
                            v-model="progressNote.therapyType"
                            :items="sessionTypes"
                            label="Type of session"
                            dense
                            outlined
                            :rules="[(v) => !!v || 'required']"
                            :disabled="viewProgressMode"
                          ></v-select>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model="progressNote.sessionLength"
                            label="Length of session"
                            outlined
                            dense
                            :disabled="viewProgressMode"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" xl="3" md="4">
                          <v-checkbox
                            v-model="progressNote.sessionLength"
                            value="0"
                            label="No session"
                            :disabled="viewProgressMode"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                          <!-- <v-list flat>

                          </v-list>

                              <v-list-item
                                v-for="(item, i) in assessmentPsychotherapyQuestions"
                                :key="i"
                              >

                              </v-list-item> -->
                          <v-row>
                            <v-col cols="3">
                              <p>
                                {{
                                  assessmentPsychotherapyQuestionsByPrecedence ===
                                  null
                                    ? ""
                                    : assessmentPsychotherapyQuestionsByPrecedence(
                                        1
                                      ).question
                                }}
                              </p>
                            </v-col>
                            <v-col>
                              <v-radio-group
                                :mandatory="false"
                                v-model="selectedOutcomeMeasure"
                                row
                                :disabled="viewProgressMode"
                              >
                                <v-radio
                                  v-for="answer in assessmentPsychotherapyAnswersByQuestion(
                                    1
                                  )"
                                  :key="answer.id"
                                  :label="answer.answerOption"
                                  :value="answer.id"
                                ></v-radio>
                                <!-- <v-radio
                                      v-model="otherOutcome_measure"
                                      label="Other"
                                      :value="true"
                                    ></v-radio> -->
                              </v-radio-group>
                            </v-col>

                            <v-col cols="6" xl="3" md="4" hidden>
                              <v-text-field
                                :disabled="viewProgressMode"
                                v-model="selectedOutcomeMeasure"
                                label="Other"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>

                            <!-- <v-col cols="6" xl="3" md="4">
                              <v-text-field
                                v-model="selectedOutcomeMeasure"
                                v-if="otherOutcome_measure"
                                label="Other"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col> -->
                            <v-col cols="12" sm="12">
                              <p>
                                Treatment is sue/target Symptoms/Behaviors On
                                Current treatment Plan address during session
                              </p>
                              <v-textarea
                                v-model="progressNote.treatmentIssue"
                                filled
                                :disabled="viewProgressMode"
                              ></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <p>
                                {{
                                  assessmentPsychotherapyQuestionsByPrecedence ===
                                  null
                                    ? ""
                                    : assessmentPsychotherapyQuestionsByPrecedence(
                                        2
                                      ).question
                                }}
                              </p>
                              <v-row>
                                <v-col
                                  cols="6"
                                  xl="3"
                                  md="4"
                                  v-for="answer in assessmentPsychotherapyAnswersByQuestion(
                                    2
                                  )"
                                  :key="answer.id"
                                >
                                  <v-checkbox
                                    v-model="selectedMedicalCondition"
                                    multiple
                                    :label="answer.answerOption"
                                    :value="answer.id"
                                    :disabled="viewProgressMode"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <p>
                            Comorbid medical condition impacting psychological
                            status. specify
                          </p>
                          <v-textarea hint="specify" filled></v-textarea>
                        </v-col>
                        <v-row>
                          <v-col cols="3">
                            <p>
                              {{
                                assessmentPsychotherapyQuestionsByPrecedence ===
                                null
                                  ? ""
                                  : assessmentPsychotherapyQuestionsByPrecedence(
                                      3
                                    ).question
                              }}
                            </p>
                          </v-col>
                          <v-col cols="8">
                            <v-row>
                              <v-col
                                cols="6"
                                xl="3"
                                md="4"
                                v-for="answer in assessmentPsychotherapyAnswersByQuestion(
                                  3
                                )"
                                :key="answer.id"
                              >
                                <v-checkbox
                                  v-model="selectedTechnique_used"
                                  multiple
                                  :label="answer.answerOption"
                                  :value="answer.id"
                                  :rules="[(v) => !!v || 'required']"
                                  :disabled="viewProgressMode"
                                ></v-checkbox>
                              </v-col>

                              <v-col cols="6">
                                <v-textarea
                                  v-model="progressNote.technique_used"
                                  v-if="otherTechnique_used"
                                  hint="Open text..."
                                  height="2"
                                  filled
                                  :disabled="viewProgressMode"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-col cols="12" sm="12">
                          <p>
                            Intervantion Strategies Implemented and Session
                            Focus or Theme
                          </p>

                          <v-textarea
                            v-model="progressNote.interventionStrategy"
                            hint="specify"
                            :rules="[(v) => !!v || 'required']"
                            filled
                            :disabled="viewProgressMode"
                          ></v-textarea>
                        </v-col>
                        <v-row>
                          <v-col cols="3">
                            <p>
                              {{
                                assessmentPsychotherapyQuestionsByPrecedence ===
                                null
                                  ? ""
                                  : assessmentPsychotherapyQuestionsByPrecedence(
                                      4
                                    ).question
                              }}
                            </p>
                          </v-col>
                          <v-col cols="8">
                            <v-row>
                              <v-col
                                cols="6"
                                xl="3"
                                md="4"
                                v-for="answer in assessmentPsychotherapyAnswersByQuestion(
                                  4
                                )"
                                :key="answer.id"
                              >
                                <v-checkbox
                                  v-model="selectedPatient_response"
                                  multiple
                                  :label="answer.answerOption"
                                  :value="answer.id"
                                  :rules="[(v) => !!v || 'required']"
                                  :disabled="viewProgressMode"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-col cols="12" sm="12">
                          <p>Evidence of Patient Response</p>
                          <v-textarea
                            v-model="progressNote.patientResponseEvidence"
                            hint="specify"
                            filled
                            :disabled="viewProgressMode"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <p>Future Treatment/Follow-up</p>
                          <v-textarea
                            v-model="progressNote.futureTreatmentPlan"
                            hint="Issues (Plan)"
                            filled
                            :disabled="viewProgressMode"
                          ></v-textarea>
                        </v-col>

                        <v-col cols="12" sm="12">
                          <p>
                            {{
                              assessmentPsychotherapyQuestionsByPrecedence ===
                              null
                                ? ""
                                : assessmentPsychotherapyQuestionsByPrecedence(
                                    5
                                  ).question
                            }}
                          </p>
                          <v-radio-group
                            v-model="selectedTreatment_plan_action"
                            :mandatory="false"
                            row
                            :disabled="viewProgressMode"
                          >
                            <v-radio
                              v-for="answer in assessmentPsychotherapyAnswersByQuestion(
                                5
                              )"
                              :key="answer.id"
                              :label="answer.answerOption"
                              :value="answer.id"
                            ></v-radio>
                            <!-- <v-radio
                              label="Change Diagnosis"
                              value="Change_Diagnosis"
                            ></v-radio> -->
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions v-if="!viewProgressMode">
                    <v-btn
                      color="green"
                      class="ml-12 mt-n10 mb-4"
                      @click="onSubmit"
                      :disabled="!valid"
                      >Save</v-btn
                    >
                    <v-spacer />
                    <v-btn class="mt-n10 mb-4" @click="close">Cancel</v-btn>
                  </v-card-actions>
                  <v-card-actions v-else>
                    <v-spacer />
                    <v-btn class="mt-n10 mb-4" color="red" @click="close"
                      >Close</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card>
      </template>
      <template v-slot:item.id="{ index }">
        <span>{{ index + 1 }}</span>
      </template>
      <template v-slot:item.names="{ item }">
        <span
          >{{ item.beneficiaryFirstName }} {{ item.beneficiaryLastName }}</span
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="viewProgressNote(item)"
          v-if="showEdit"
        >
          mdi-eye
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="updateProgressNoteOpener(item)"
          v-if="showEdit"
        >
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteDialogOpener(item)" v-if="showDelete">
          mdi-delete
        </v-icon>
        <!-- <v-icon
          small
          @click="completeTrainingClassDialog(item)"
          v-if="item.state !== 'COMPLETED'"
        >
          mdi-check
        </v-icon> -->
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this trade class ?</p>
          <v-btn link small color="blue" @click="deleteProgressNote()"
            >Yes</v-btn
          >
          <v-btn link small color="blue" @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectProgressNotesPerPage"
            v-model="progressNotesPerPage"
            label="ProgressNotes per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="10">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
// import { WebCam } from 'vue-web-cam'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import TrainingClass from '@/models/TrainingClass'
export default {
  data () {
    return {
      selectProgressNotesPerPage: [5, 10, 15, 20, 25, 30],
      totalProgressNotes: 0,
      page: 1,
      pageCount: 0,
      progressNotesPerPage: 20,
      viewProgressMode: false,
      toBeDeletedProgressNoteId: null,
      searchMode: false,
      editProgressMode: false,
      otherTechnique_used: false,
      otherOutcome_measure: false,
      selectedOutcomeMeasure: '',
      selectedMedicalCondition: [],
      selectedTechnique_used: [],
      selectedPatient_response: [],
      selectedTreatment_plan_action: '',
      progressNote: {
        transferId: '',
        therapyDate: '',
        primaryDx: '',
        facility: '',
        sessionLength: '',
        therapyType: '',
        treatmentIssue: '',
        medicalCondition: '',
        interventionStrategy: '',
        patientResponseEvidence: '',
        futureTreatmentPlan: '',
        selectedOptions: []
      },
      valid: false,
      sessionTypes: ['INDIVIDUAL', 'GROUP', 'CRISIS_INTERVENTION'],
      fromDate: null,
      sessionDateMenu: false,
      fromDateMenu: false,
      sessionDate: null,
      toDate: null,
      toDateMenu: false,
      dialog: false,
      privileges: null,
      showEdit: false,
      showDelete: false,
      showCreate: false,
      deleteDialog: false,
      names: null,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'number'
        },
        { text: 'Session date', value: 'therapyDate' },
        { text: 'Type of service', value: 'therapyType' },
        { text: 'Facility Name', value: 'facility' },
        { text: 'Beneficiary', value: 'names' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      singleSelect: false,
      behaviours: [
        { label: 'Once', value: 'once' },
        { label: 'Occasionally', value: 'occasionally' },
        { label: 'Frequently', value: 'frequently' }
      ],
      first: null,
      second: null,
      third: null,
      fourth: null,
      fifth: null,
      sixth: null,
      seventh: null,
      selected: [],
      filterIntake: null,
      intakes: [],
      editMode: -1,
      editedTrainingClass: new TrainingClass(),
      trainingClassItem: null,
      transferId: null,
      errors: null,
      tomorrow1: null,
      panel: 3,
      delinquents: [],
      delinquentFirstName: null,
      delinquentLastName: null,
      placeOfBirthId: null,
      residenceId: null,
      recordDates: null,
      arrivalDates: null,
      exitDates: null,
      released: null,
      states: null,
      eligibilityStates: null,
      intakeId: null,
      intakeSet: null,
      decisionIds: null,
      physicallyScreened: null,
      screeningApprovalStatuses: null,
      onGraduationList: null,
      graduationId: null,
      expand: false,
      graduationApprovalStatuses: null,
      postRehabDestinationId: null,
      hasOccupation: null,
      organizationId: null,
      sort: 'recordDate,desc'
    }
  },

  computed: {
    ...mapGetters('psychotherapy', [
      'progressNotes',
      'progressNotesByDate',
      'assessmentPsychotherapyQuestions',
      'assessmentPsychotherapyAnswersByQuestion',
      'assessmentPsychotherapyQuestionsByPrecedence'
    ]),
    formTitle () {
      return !this.editProgressMode
        ? 'Add Progress Note'
        : 'Edit Progress Note'
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.readAllProgressNotes()
      }
    },
    progressNotesPerPage: {
      handler () {
        this.page = 1
        this.readAllProgressNotes()
      }
    },
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  mounted () {
    this.getAssessmentPsychotherapyQuestions()
    this.readAllProgressNotes()
    this.loadAllDelinquents()
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  created () {
    this.showPanels = true
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    otherSelected (id) {
      return this.selectedOptions
    },
    ...mapActions('store', ['switchToClass']),
    ...mapActions('psychotherapy', [
      'addProgressNotes',
      'readProgressNotes',
      'getAssessmentPsychotherapyQuestions',
      'updateProgressNotes',
      'removeProgressNote'
    ]),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    readAllProgressNotes () {
      this.$psychotherapyService
        .getProgressNotes({
          from:
            this.fromDate === null || this.fromDate === ''
              ? ''
              : this.$moment(this.fromDate).format('DD-MMM-YYYY'),
          to:
            this.toDate === null || this.toDate === ''
              ? ''
              : this.$moment(this.toDate).format('DD-MMM-YYYY'),
          transferStates: 'REHABILITATION',
          page: this.page - 1,
          size: this.progressNotesPerPage
        })
        .then((res) => {
          if (res) {
            this.totalProgressNotes = res.totalElements
            this.readProgressNotes(res.content)
            res.content.forEach(element => {
              element.number = res.content.indexOf(element) + 1
            })
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('This class have never started')
          }
        })
    },
    searchProgressNotes () {
      this.searchMode = true
    },
    deleteDialogOpener (item) {
      this.deleteDialog = true
      this.toBeDeletedProgressNoteId = item.id
    },
    deleteProgressNote () {
      this.close()
      this.$psychotherapyService
        .deleteProgressNotes(this.toBeDeletedProgressNoteId)
        .then((response) => {
          if (response.status === 'success') {
            this.removeProgressNote(this.toBeDeletedProgressNoteId)
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    assignTobeViewed (response) {
      return new Promise((resolve, reject) => {
        this.progressNote = response
        this.progressNote.selectedOptions = []
        this.sessionDate = response.therapyDate
        response.multipleChoiceQuestions.forEach((el) => {
          if (el.precedence === 1) {
            this.selectedOutcomeMeasure = el.answerOptions.find(
              (answer) => answer.selected === true
            ).id
          }
          if (el.precedence === 5) {
            this.selectedTreatment_plan_action = el.answerOptions.find(
              (answer) => answer.selected === true
            ).id
          }
          if (el.precedence === 2) {
            el.answerOptions
              .filter((answer) => answer.selected === true)
              .forEach((el) => {
                this.selectedMedicalCondition.push(el.id)
              })
          }
          if (el.precedence === 3) {
            el.answerOptions
              .filter((answer) => answer.selected === true)
              .forEach((el) => {
                this.selectedTechnique_used.push(el.id)
              })
          }
          if (el.precedence === 4) {
            el.answerOptions
              .filter((answer) => answer.selected === true)
              .forEach((el) => {
                this.selectedPatient_response.push(el.id)
              })
          }
        })
        resolve(true)
      })
    },
    viewProgressNote (item) {
      this.$psychotherapyService
        .getProgressNoteById({
          params: { expand: 'therapy_responses' },
          id: item.id
        })
        .then((response) => {
          if (response !== null) {
            this.assignTobeViewed(response).then((res) => {
              if (res) {
                this.editProgressMode = false
                this.viewProgressMode = true
                this.dialog = true
              }
            })
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    editProgressNote () {
      this.selectedMedicalCondition.forEach((el) => {
        this.progressNote.selectedOptions.push({ selectedOptionID: el })
      })

      this.selectedTechnique_used.forEach((el) => {
        this.progressNote.selectedOptions.push({ selectedOptionID: el })
      })

      this.selectedPatient_response.forEach((el) => {
        this.progressNote.selectedOptions.push({ selectedOptionID: el })
      })
      this.progressNote.selectedOptions.push({
        selectedOptionID: this.selectedTreatment_plan_action
      })
      this.progressNote.selectedOptions.push({
        selectedOptionID: this.selectedOutcomeMeasure
      })

      this.$psychotherapyService
        .updateProgressNotes(this.progressNote)
        .then((response) => {
          if (response !== null) {
            this.updateProgressNotes(response)
            this.close()
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    updateProgressNoteOpener (item) {
      this.$psychotherapyService
        .getProgressNoteById({
          params: { expand: 'therapy_responses' },
          id: item.id
        })
        .then((response) => {
          if (response !== null) {
            this.assignTobeViewed(response).then((res) => {
              if (res) {
                this.viewProgressMode = false
                this.editProgressMode = true
                this.dialog = true
              }
            })
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    fromDateSave (date) {
      this.$refs.menu.save(date)
    },
    toDateSave (date) {
      const date1 = this.$moment(date).format('DD-MMM-YYYY')
      this.$refs.menu.save(date1)
    },
    sessionDateSave (date) {
      this.$refs.menu.save(date)
      this.progressNote.therapyDate = this.$moment(date).format('DD-MMM-YYYY')
    },
    loadAllDelinquents () {
      // const pageNumber = this.page - 1
      // this.delinquentLastName = this.delinquentFirstName
      // this.graduationApprovalStatuses = 'APPROVED'
      // this.expand = true
      this.$delinquentService
        .fetchTransfersAnyParams({ state: 'REHABILITATION' })
        .then((response) => {
          if (!response.status) {
            this.delinquents = response.content
            this.delinquents.forEach((element) => {
              element.names =
                element.delinquent.firstName +
                ' ' +
                element.delinquent.lastName
              element.delinquent.dateOfBirth = this.$moment(
                element.delinquent.dateOfBirth
              ).format('YYYY')
              element.arrivalDate = this.$moment(element.arrivalDate).format(
                'DD-MMM-YYYY'
              )
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },

    close () {
      this.deleteDialog = false
      this.dialog = false
      this.viewProgressMode = false
      this.editProgressMode = false
      this.progressNote = {}
      this.selectedTreatment_plan_action = ''
      this.outcome_measure = ''
      this.selectedTechnique_used = []
      this.selectedMedicalCondition = []
      this.selectedPatient_response = []
    },
    onSubmit () {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.editProgressMode) {
        this.editProgressNote(this.progressNote)
      } else {
        //         selectedOutcomeMeasure: {
        //   id: '',
        //   other: ''
        // },
        // selectedMedicalCondition: [],
        // selectedTechnique_used: [],
        // selectedPatient_response: [],
        // selectedTreatment_plan_action: '',
        // this.progressNotes.selectedOptions.push({
        //   this.selectedMedicalCondition
        // })
        this.selectedMedicalCondition.forEach((el) => {
          this.progressNote.selectedOptions.push({ selectedOptionID: el })
        })

        this.selectedTechnique_used.forEach((el) => {
          this.progressNote.selectedOptions.push({ selectedOptionID: el })
        })

        this.selectedPatient_response.forEach((el) => {
          this.progressNote.selectedOptions.push({ selectedOptionID: el })
        })
        this.progressNote.selectedOptions.push({
          selectedOptionID: this.selectedTreatment_plan_action
        })
        this.progressNote.selectedOptions.push({
          selectedOptionID: this.selectedOutcomeMeasure
        })
        this.$psychotherapyService
          .addProgressNotes(this.progressNote)
          .then((response) => {
            if (response !== null) {
              this.addProgressNotes(response)
              this.close()
            } else {
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      }
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.indexOf('UPDATE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.indexOf('DELETE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showDelete = true)
        : (this.showDelete = false)
      this.privileges.indexOf('UPDATE_PSYCHOTHERAPYPROGRESSNOTES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.indexOf('DELETE_PSYCHOTHERAPYPROGRESSNOTES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showDelete = true)
        : (this.showDelete = false)
      this.privileges.indexOf('CREATE_PSYCHOTHERAPYPROGRESSNOTES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showCreate = true)
        : (this.showCreate = false)
    }
  }
}
</script>
<style scoped>
.btn {
  cursor: pointer;
}
</style>
